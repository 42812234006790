<template>
  <footer>
    <div class="mt-md-2 container-fluid text-center">
      <p>
        MOTWAPI created by <a href="http://www.smccall.com">Sam McCall</a>.
        Monster of the Week designed by Michael Sands and Steve Hickey.
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
